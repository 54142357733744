import './App.css';
import Pote from "./assets/images/pote.png"
import logo from "./assets/images/logo.svg"
import {useState} from "react";
import imagen from "./assets/images/eureka.png";
import zip from "./assets/images/eureka.zip";
import axios from "axios";

function App() {
    const [text, setText] = useState("");
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);
    const [downloaded, setDownloaded] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [error, setError] = useState(false);

    const isTextEmpty = () => { return text.length <= 0 }

    const download = async () => {
        setDownloading(true);
        await sleep(2000);
        setDownloading(false);
        setDownloaded(true);
        await sleep(5000);
        setDownloaded(false);
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const send = async () =>{
        setLoading(true);

        try {

            const data = {
                email: text
            };

            axios.post(`https://guau.ar/api/send.php`, { data })
                .then(res => {
                    setError(false);
                })
                .catch(function (error) {
                    setError(true);
                }).then(() => {
                setLoading(false);
                setText("");
                setSent(true);
            })
        } catch (e) {
            setError(true);
        }

    }

    function isTextEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }


    return (
        <div className="App">
            <img src={Pote} className="pote"/>
            <main className="rectangle">
                <img src={logo} className="logo"/>
                <h1 className={sent&& "sent"}>{sent? "¡Listo! Ya anotamos tu email." : "¡Se vienen cositas!"}</h1>
                <p>{sent ? "En cuanto tengamos novedades te vamos a escribir. Mientras tanto, te dejamos una imagen para descargar y colorear." : "Todavía estamos trabajando, pero dejános tu email y te avisamos cuando haya novedades."}</p>
                { (!sent || error) &&
                    <>
                        <input className={!isTextEmpty() && !isTextEmail(text) ? "error" : ""} type="text" name="email" value={text} placeholder={"Tu email"} onChange={(e) => { setText(e.target.value) }}/>
                        <label className={!isTextEmpty() && !isTextEmail(text) ? "error" : ""}>¿Es ese tu mail? No parece tener el formato correcto.</label>
                    </>
                }
                {
                    loading ?
                        <div className={"loading"}></div>
                        :
                        ( !sent && <button className="send" onClick={send} disabled={isTextEmpty() || !isTextEmail(text)}>Enviar</button>)
                }
                {
                    sent && !error &&
                    <>
                        <a href={imagen} download={"eureka"} className="download-mobile" onClick={download}>
                            <button className="download">
                                <svg className={(downloading ? "animate" : "") + " arrow " +  (downloaded ? "hide" : "")} width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className={!downloaded && "show"} d="M17.8583 8.73497L17.5718 8.44851C17.3822 8.25889 17.0756 8.25889 16.8859 8.44851L9.68815 15.6503L9.68815 0.484156C9.68815 0.21787 9.47028 0 9.204 0L8.80054 0C8.53425 0 8.31638 0.21787 8.31638 0.484156L8.31638 15.6503L1.11457 8.44851C0.924939 8.25889 0.618307 8.25889 0.428679 8.44851L0.142221 8.73497C-0.0474069 8.9246 -0.0474069 9.23123 0.142221 9.42086L8.65529 17.938C8.84492 18.1276 9.15155 18.1276 9.34118 17.938L17.8542 9.42086C18.0479 9.2272 18.0479 8.9246 17.8583 8.73497Z" fill="black"/> }
                                    <path className={(downloaded ? "show" : "") + " tick"} d="M19.1468 0.164742L6.81663 12.4949L2.28607 7.96437C2.06641 7.74471 1.71026 7.74471 1.49055 7.96437L0.164742 9.29018C-0.0549141 9.50983 -0.0549141 9.86599 0.164742 10.0857L6.41885 16.3398C6.63851 16.5595 6.99466 16.5595 7.21437 16.3398L21.2681 2.28607C21.4878 2.06641 21.4878 1.71026 21.2681 1.49055L19.9423 0.164742C19.7226 -0.0549141 19.3665 -0.0549141 19.1468 0.164742Z" fill="black"/> }
                                </svg>
                                Descargar tu imagen
                            </button>
                        </a>
                        <a href={zip} download={"eureka"}  className="download-desktop" onClick={download}>
                            <button className="download">
                                <svg className={(downloading ? "animate" : "")  + " arrow " +  (downloaded ? "hide" : "")} width="23" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className={!downloaded && "show"} d="M17.8583 8.73497L17.5718 8.44851C17.3822 8.25889 17.0756 8.25889 16.8859 8.44851L9.68815 15.6503L9.68815 0.484156C9.68815 0.21787 9.47028 0 9.204 0L8.80054 0C8.53425 0 8.31638 0.21787 8.31638 0.484156L8.31638 15.6503L1.11457 8.44851C0.924939 8.25889 0.618307 8.25889 0.428679 8.44851L0.142221 8.73497C-0.0474069 8.9246 -0.0474069 9.23123 0.142221 9.42086L8.65529 17.938C8.84492 18.1276 9.15155 18.1276 9.34118 17.938L17.8542 9.42086C18.0479 9.2272 18.0479 8.9246 17.8583 8.73497Z" fill="black"/>
                                    <path className={(downloaded ? "show" : "") + " tick"} d="M22.2551 1.28646L21.9687 1C21.779 0.810372 21.4724 0.810372 21.2828 1L8.51653 13.7691C8.51653 13.7691 8.51653 13.7691 8.16955 14.1186C7.82257 14.4682 7.48366 14.1186 7.48366 14.1186L7.14475 13.769L1.97235 8.59489C1.78272 8.40527 1.28646 8.59489 1.28646 8.59489L1 8.88135C0.810372 9.07098 0.810372 9.37761 1 9.56724L7.48366 16.0567C7.67329 16.2463 7.97992 16.2463 8.16955 16.0567L22.2511 1.97235C22.4448 1.77868 22.4448 1.47609 22.2551 1.28646Z" fill="black"/>
                                </svg>
                                Descargar tu imagen
                            </button>
                        </a>
                    </>

                }
                {error &&
                <div className="error">
                    <div className="error-circle">
                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0" maskUnits="userSpaceOnUse" x="8" y="8" width="20" height="20">
                                <path d="M19.5341 18L27.7879 9.74613C28.0707 9.46338 28.0707 9.00617 27.7879 8.72342L27.2766 8.21206C26.9938 7.92931 26.5366 7.92931 26.2539 8.21206L18 16.4659L9.74613 8.21808C9.46338 7.93533 9.00617 7.93533 8.72342 8.21808L8.21206 8.72943C7.92931 9.01218 7.92931 9.46939 8.21206 9.75214L16.4659 18L8.21808 26.2539C7.93533 26.5366 7.93533 26.9938 8.21808 27.2766L8.72943 27.7879C9.01218 28.0707 9.46939 28.0707 9.75214 27.7879L18 19.5341L26.2539 27.7879C26.5366 28.0707 26.9938 28.0707 27.2766 27.7879L27.7879 27.2766C28.0707 26.9938 28.0707 26.5366 27.7879 26.2539L19.5341 18Z" fill="white"/>
                            </mask>
                            <g mask="url(#mask0)">
                                <rect x="6" y="6" width="24" height="24" fill="#EE5D52"/>
                            </g>
                        </svg>
                    </div>
                    <p className="error-text">¡Uy! Tuvimos un problema. <br/> Volvé a intentar mas tarde.</p>

                </div>
                }
            </main>
        </div>
    );
}

export default App;
